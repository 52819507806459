
.el-int {
    width: 260px;
    margin-left: 20px;
}

.page-main {
    width: 100%;
    height: 50px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

::v-deep .el-radio-button__orig-radio:checked+.el-radio-button__inner {
    background-color: #1E33E3;
    border-color: #1E33E3;
}

.dy-products-rank {
    width: 100%;
    background-color: #fff;
    padding: 10px 20px 10px;
    box-sizing: border-box;

    .dy-header {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .rank-type {
            margin-right: 20px;
        }

        .rank-date {
            margin-right: 20px;
        }

        .search-input {
            width: 300px;
        }
    }
}

.category-list {
    display: flex;
    margin-top: 20px;

    .cate-left {}

    .cate-content {
        width: 1%;
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        margin-left: -12px;

        .cate-item {
            padding: 0 8px;
            margin: 0 0 10px 12px;
            border: 1px solid transparent;
            cursor: pointer;

            &.current {
                border-color: #1E33E3;
                color: #1E33E3;
            }
        }
    }
}

.cate-children {
    .child {
        cursor: pointer;
        line-height: 36px;
        padding-left: 10px;

        &:hover {
            background: #f5f7fa;
        }

        &.current {
            color: #1E33E3;
        }
    }
}

.good-info {
    display: flex;
    align-items: center;

    img {
        width: 46px;
        height: 46px;
    }

    .good-right {
        width: 1%;
        flex: 1;
        margin: 0 10px;

        .num {
            font-weight: 700;
        }
    }

    .iconfont {
        color: #ff0000;
        font-size: 20px;
    }
}