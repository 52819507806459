
.category-main {
    & * {
        box-sizing: border-box;
    }
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    .category-title {
        padding-left: 10px;
        padding-top: 10px;
        width: 100px;
        font-size: 12px;
        font-weight: 400;
        color: #999;
    }
    .category-list-box {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        .cate-item {
            span.cate-name {
                margin-bottom: 15px;
                margin-right: 15px;
                background-color: #fafafb;
                font-size: 12px;
                display: inline-block;
                padding: 5px 12px;
                border-radius: 15px;
                cursor: pointer;
            }
            .cate-name {
                &.active {
                    color: #1E33E3;
                }
            }
        }
    }
}
.select-box {
    & * {
        box-sizing: border-box;
    }
    box-sizing: border-box;
    height: 200px;
    font-size: 16px;
    padding: 6px 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    ::v-deep .el-scrollbar {
        .el-scrollbar__wrap {
            overflow-x: hidden;
        }

    }
    .el-scrollbar__wrap {
        overflow-x: hidden;
    }
    .col-ul {
        height: 200px;
        width: 200px;
        .cate-unit {
            height: 34px;
            cursor: pointer;
            padding: 0 10px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            &.active {
                background-color: #EEEEEE;
            }
            &:hover {
                background-color: #EEEEEE;
            }
            .cate-name {
                margin-left: 5px;
                width: 142px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                span.cate-txt {
                    display: inline-block;
                    width: 100%;
                    overflow: hidden;
                    font-size: 14px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    &.active {
                        color: #1E33E3;
                    }
                }
            }
            .select-icon {
                width: 16px;
                height: 16px;
            }
        }
    }
}
